<template>
  <div id="portrait">
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      style="enable-background:new 0 0 500 500;"
      xml:space="preserve"
    >
      <g>
        <path
          class="logo-color-1"
          d="M367.5,178c-17.2-19.5-56.1-45.4-89.4-26c38.5-0.6,57.6,18.3,79.3,34.7C361,184.1,367.3,184.1,367.5,178z"
        ></path>
        <path
          class="logo-color-1"
          d="M283.9,188.1c5.1-3.5,11.3-6,13-13C288.4,174.8,281.9,182.7,283.9,188.1z"
        ></path>
        <path
          class="logo-color-1"
          d="M426.6,201.1c3.1-3.5-3.2-6.4-5.8-8.7C418.2,195.6,420.6,202.3,426.6,201.1z"
        ></path>
        <path
          class="logo-color-1"
          d="M389.1,227.1c-5-10.9-2.6-18.5-7.2-31.8c-1.8-0.1-2-1.9-4.3-1.4c-0.2,29.1,13.8,44,24.5,62.1c-11.2,5-26.5,13.4-41.8,11.6
		c-7.4-0.9-19.8-13.6-21.6,0c18.3,8.9,48.3,3.3,67.8-5.8C408.6,242.5,394.8,239.6,389.1,227.1z"
        ></path>
        <path
          class="logo-color-1"
          d="M348.8,198.3c-14.8,1.8-40.5-4.8-49,2.9C292.9,226.9,361.3,233.7,348.8,198.3z M325.9,200.6c5.2,2.7,14.7,1.1,20,4.9
		c-1.9,9.1-9,13.1-20.2,13C329.8,211.3,329.9,208.7,325.9,200.6z"
        ></path>
        <path
          class="logo-color-1"
          d="M425.2,209.8c-10-2.2-24.7-6.8-36-2.9C387.7,227.5,424.3,230.3,425.2,209.8z M412.2,212.7c1.2,0.8,2.3,1.5,4.3,1.4
		c0.4,3.8-2,4.7-5.8,4.3C410.1,215.4,411.7,214.6,412.2,212.7z"
        ></path>
        <path
          class="logo-color-1"
          d="M151.3,286.3c-19-7.6-26.4-42.6-33.2-62.1c-5.4,19.8,5,25,11.5,41.9C134.3,278.2,137.8,291.8,151.3,286.3z"
        ></path>
        <path
          class="logo-color-1"
          d="M442.5,240.1c-5.7-22.9,2.1-44.9-1.4-66.4c-1-5.9-6.7-11.5-8.6-17.3c-6.4-19.2-5.6-41.8-8.6-66.4c5.6-1.1,8.6-4.9,13-7.2
		c4.1-18.2-12.6-25.1-20.2-31.8c-8.3-7.2-15.5-15.4-20.2-18.8C364.6,9.6,299.5-5.5,246.4,1.9c-79,11-131.5,86.2-116.8,174.7
		c3.5,21.1,3.7,41.1,11.5,54.9c-2.7,0.5-6.6-7.2-7.2,0c3.3,8.2,14.2,8.9,17.3,17.3c-2.2,7-10.4,7.9-8.6,18.8
		c8.4,6.8,13.9,3.7,15.9,13c1.5,7.4-2.9,2.8-5.8,10.1c0,0,1.7,11.3-1.4,13c-35.6,12.2-30.1,66.7-51.9,95.3
		c-4.4,5.8-11.6,9.5-13,14.4c-2.2,7.9,1.7,18.4,0,24.5c2,4.6,8.5-5.7,11.5,0c-7.2,17.3-41.6,36.3-40.4,62.1c95.1,0,276.8,0,371.9,0
		c1.8-29.3-50.9-42.9-59.8-61.4c23.4-13.7,25.2-41.9,20.9-70c-3.7,15-8,29.3-15.6,39.6l-2.3-19.4c0,0-26.1-15.1-22.4,0
		c2.7,11.2-17.1,24.4-27.7,30.5c-19.8-6.2-48-19.7-54.6-34.8c-4.5-10.3-4.1-21.8-5.3-31.4c1.3-5,3.4-12.3,7-21.6
		c7.1-18.1,19.1-44.2,19.1-44.2l61.5,21.8c0,0,0-9.7,0-19.8c0-10.1-66.8-7.6-66.8-7.6s-22.6,34.4-32.1,55c-1.1,1.2-1.9,3.3-2.5,5.8
		c-0.2,0.5-0.3,1.1-0.4,1.6c-1.4,6.9-2.1,16.1-5.1,20.3c-30.8-23-48.4-66.7-53.3-111.2c-1.3-12.2-3.1-33.9,0-41.9
		c6.4-16.8,10.9-37.7,17.3-56.3c11.1-32.4,45.3-64,76.4-70.7c20.4-4.5,36.7,2.6,56.2,2.9c26,0.3,52-9.5,76.4,2.9
		c5.2,27.9,11.9,66.7,17.3,85.2c-11.6,3.3-28.3,1.5-36,8.7c13.4,0.8,28.8-4.8,38.9,1.4C437.3,205.2,434.2,232,442.5,240.1z
		 M142.6,234.3c1.8-5.4,10.7,1.2,11.5,5.8C147.1,241.4,148.7,234.1,142.6,234.3z M154.2,267.6c-2.1-0.8-3.6-2.1-5.8-2.9
		c-0.2-3.6,2.3-4.5,2.9-7.2C157.1,256.1,156.3,265.3,154.2,267.6z M205.4,382.4c5.5,3.9,10.7,9.8,16.3,15.3c2.2,2.1,4.6,4.2,7,6.3
		c3.3,2.6,6.7,4.9,10.5,6.5c-0.8,0.2-1.6,0.3-2.5,0.4c35.2,29.9,83.9,61.6,121,34.6c0,34.9-75.7,37.3-93.7,28.5
		c-23.1-11.3-60.2-24.5-71.7-102.5C194.5,372.3,199,376.4,205.4,382.4z"
        ></path>
        <path
          class="logo-color-1"
          d="M429.4,286c2.2-7.1,9-12.8,4.6-16.8C431,277,426.8,280.5,429.4,286z"
        ></path>
        <path
          class="logo-color-1"
          d="M381.9,312.3c-35,6.5-64.4-3.4-87.9-13c-0.6-3.8-0.7-7.9-2.9-10.1c-4.7,6.9-8.3,14.8-11.5,23.1c15.6,21,26,55.5,57.7,60.6
		c24.4,4,39.3-9,59.1-11.5c0.9-7.9,9-9.2,4.3-18.8c-5.8-0.5-8,2.6-14.4,1.4c-2.7-5-5.2-10.2-4.3-18.8c1.9-3.9,7.9-3.6,10.1-7.2
		c0.4-4.2-2.2-16.1-9.6-17.3c4.6-2.1,8.9-3.4,10.3-2c2.5,2.5,4.9,17.3,6.1,26.2c-0.9,6-1.8,11.9,1.8,16.1c-0.1-4.4,0.6-7.8,0.7-10.9
		c0,0,0-0.1,0-0.1c0.9-6.7-2.6-35.9-9.4-39c-6.7-3.1-24.5-1.9-24.5-1.9V309c0,0,7.2-4.6,14-7.8C379.6,304,386.6,308.1,381.9,312.3z
		 M298.3,334c-6.5-5.5-9-15.1-13-23.1c1.2-0.3,1.4-1.4,2.9-1.4c12.8-2.8,27.4,10.5,43.2,13c16.1,2.5,32.9-5,44.7,2.9
		C363.6,344.8,319.4,324.7,298.3,334z"
        ></path>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  data: function() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
#portrait {
  svg {
    width: 100%;
    height: 100%;
  }
  .logo-color-1 {
    fill: $color-green;
  }
}
</style>